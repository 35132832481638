var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { bordered: false } },
    [
      _c(
        "a-card-grid",
        { staticStyle: { width: "100%" }, attrs: { hoverable: false } },
        [
          _c(
            "a-form-model",
            {
              ref: "salesOrderForm",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-align": "left",
                "label-col": _vm.formCol,
                "wrapper-col": _vm.formCol
              }
            },
            [
              _c(
                "a-row",
                { attrs: { gutter: [16, 16], type: "flex" } },
                [
                  _c(
                    "a-col",
                    { attrs: { sm: 24, md: 12 } },
                    [
                      _c("p", { staticClass: "text-subtitle-1" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t("lbl_customer_detail")) + " "
                        )
                      ]),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_customer_name"),
                            prop: "customerId"
                          }
                        },
                        [
                          _c("CSelectCustomer", {
                            attrs: {
                              "prop-customer-name": _vm.form.customer,
                              "for-list": false
                            },
                            on: { "on-select": _vm.onSelectCustomer },
                            model: {
                              value: _vm.form.customerId,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "customerId", $$v)
                              },
                              expression: "form.customerId"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_customer_contact"),
                            prop: "customerContact"
                          }
                        },
                        [
                          _c("a-input", {
                            attrs: {
                              type: "tel",
                              placeholder: _vm.$t("lbl_type_here")
                            },
                            on: { change: _vm.commitStore },
                            model: {
                              value: _vm.form.customerContact,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "customerContact", $$v)
                              },
                              expression: "form.customerContact"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_customer_po_number"),
                            prop: "customerPoNumber"
                          }
                        },
                        [
                          _c("a-input", {
                            attrs: {
                              type: "text",
                              placeholder: _vm.$t("lbl_type_here")
                            },
                            on: { change: _vm.commitStore },
                            model: {
                              value: _vm.form.customerPoNumber,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "customerPoNumber", $$v)
                              },
                              expression: "form.customerPoNumber"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { sm: 24, md: 12 } },
                    [
                      _c("p", { staticClass: "text-subtitle-1" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t("lbl_delivery_detail")) + " "
                        )
                      ]),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_customer_shipping_address"),
                            prop: "customerShippingAddress"
                          }
                        },
                        [
                          _c("CSelectShippingAddresss", {
                            attrs: {
                              value: _vm.form.customerShippingAddress,
                              "prop-address": _vm.customerAddress
                            },
                            on: {
                              "on-select": function($event) {
                                return _vm.onSelectAddress($event, "ship")
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_eta_date"),
                            prop: "etaDate"
                          }
                        },
                        [
                          _c("a-date-picker", {
                            attrs: {
                              placeholder: _vm.$t("lbl_choose"),
                              format: _vm.DEFAULT_DATE_FORMAT
                            },
                            on: { change: _vm.onChangeDate },
                            model: {
                              value: _vm.form.etaDate,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "etaDate", $$v)
                              },
                              expression: "form.etaDate"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_eta_hour"),
                            prop: "etaHour"
                          }
                        },
                        [
                          _c("a-time-picker", {
                            attrs: {
                              placeholder: _vm.$t("lbl_choose"),
                              format: _vm.DEFAULT_TIME_FORMAT
                            },
                            on: { change: _vm.onChangeHour },
                            model: {
                              value: _vm.form.etaHour,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "etaHour", $$v)
                              },
                              expression: "form.etaHour"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_freight"),
                            prop: "freight"
                          }
                        },
                        [
                          _c("CSelectFreight", {
                            on: { "on-select": _vm.commitStore },
                            model: {
                              value: _vm.form.freight,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "freight", $$v)
                              },
                              expression: "form.freight"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { sm: 24, md: 12 } },
                    [
                      _c("p", { staticClass: "text-subtitle-1" }, [
                        _vm._v(" " + _vm._s(_vm.$t("lbl_order_detail")) + " ")
                      ]),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_order_date"),
                            prop: "orderDate"
                          }
                        },
                        [
                          _c("a-date-picker", {
                            staticClass: "w-100",
                            attrs: {
                              placeholder: _vm.$t("lbl_choose"),
                              format: _vm.DEFAULT_DATE_FORMAT
                            },
                            on: { change: _vm.onChangeDate },
                            model: {
                              value: _vm.form.orderDate,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "orderDate", $$v)
                              },
                              expression: "form.orderDate"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: { label: _vm.$t("lbl_branch"), prop: "branch" }
                        },
                        [
                          _c("CSelectBranch", {
                            on: { "on-change": _vm.commitStore },
                            model: {
                              value: _vm.form.branch,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "branch", $$v)
                              },
                              expression: "form.branch"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_sales_person_name"),
                            prop: "salesName"
                          }
                        },
                        [
                          _c("a-input", {
                            attrs: {
                              placeholder: _vm.$t("lbl_type_here"),
                              "allow-clear": ""
                            },
                            on: { change: _vm.commitStore },
                            model: {
                              value: _vm.form.salesName,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "salesName", $$v)
                              },
                              expression: "form.salesName"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_sales_type"),
                            prop: "salesType"
                          }
                        },
                        [
                          _c("CSelectSalesType", {
                            on: { change: _vm.commitStore },
                            model: {
                              value: _vm.form.salesType,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "salesType", $$v)
                              },
                              expression: "form.salesType"
                            }
                          })
                        ],
                        1
                      ),
                      _vm.showExpireDate
                        ? _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t("lbl_expired_date"),
                                prop: _vm.showExpireDate ? "expiredDate" : null
                              }
                            },
                            [
                              _c("a-date-picker", {
                                staticClass: "w-100",
                                attrs: {
                                  format: _vm.DEFAULT_DATE_FORMAT,
                                  placeholder: _vm.$t("lbl_choose")
                                },
                                on: { change: _vm.onChangeDate },
                                model: {
                                  value: _vm.form.expiredDate,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "expiredDate", $$v)
                                  },
                                  expression: "form.expiredDate"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: _vm.$t("lbl_notes") } },
                        [
                          _c("a-textarea", {
                            attrs: {
                              placeholder: _vm.$t("lbl_type_here"),
                              "auto-size": { minRows: 1, maxRows: 4 }
                            },
                            on: { change: _vm.commitStore },
                            model: {
                              value: _vm.form.notes,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "notes", $$v)
                              },
                              expression: "form.notes"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { sm: 24, md: 12 } },
                    [
                      _c("p", { staticClass: "text-subtitle-1" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t("lbl_payment_details")) + " "
                        )
                      ]),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_customer_bill_address"),
                            prop: "customerBillAddress"
                          }
                        },
                        [
                          _c("CSelectBillAddress", {
                            attrs: {
                              value: _vm.form.customerBillAddress,
                              "prop-address": _vm.customerAddress
                            },
                            on: {
                              "on-select": function($event) {
                                return _vm.onSelectAddress($event, "bill")
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_currency"),
                            prop: "currency"
                          }
                        },
                        [
                          _c("CSelectCurrency", {
                            attrs: { "prop-default": _vm.isModeCreate },
                            on: { "on-select": _vm.commitStore },
                            model: {
                              value: _vm.form.currency,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "currency", $$v)
                              },
                              expression: "form.currency"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_term_of_payment"),
                            prop: "top"
                          }
                        },
                        [
                          _c("CSelectTermOfPayment", {
                            on: { "on-select": _vm.commitStore },
                            model: {
                              value: _vm.form.top,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "top", $$v)
                              },
                              expression: "form.top"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_tax_calculation"),
                            prop: "taxCalculation"
                          }
                        },
                        [
                          _c("CSelectTaxCalculation", {
                            on: { "on-select": _vm.commitStore },
                            model: {
                              value: _vm.form.taxCalculation,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "taxCalculation", $$v)
                              },
                              expression: "form.taxCalculation"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: _vm.$t("lbl_credit_limit") } },
                        [
                          _c("span", { staticClass: "ant-form-text" }, [
                            _vm._v(
                              _vm._s(
                                _vm.formatCurrency(
                                  (_vm.form.creditLimit || 0) + ""
                                )
                              )
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }