









































































































































































































































import Vue from "vue";
import { DEFAULT_DATE_FORMAT, DEFAULT_TIME_FORMAT } from "@/models/constant/date.constant";
import moment, { Moment } from "moment";
import { formatCurrency, formatterNumber, reverseFormatNumber } from "@/validator/globalvalidator";
import { createNamespacedHelpers } from "vuex";
import { IOption } from "@interface/common.interface";
import { ContactData, IAddressDataList, ResponseContactData } from "@interface/contact.interface";
import { TAX_CALCULATION } from "@/models/enums/tax.enum";
import { ResponseDetailSalesOrder } from "@interface/sales-order.interface";
import MNotificationVue from "@/mixins/MNotification.vue";
import { initReqCreate } from "@/store/resource/sales-order.resource";
import { Mode } from "@/models/enums/global.enum";
import { SALES_ORDER_STATUS } from "@/models/enums/sales-order.enum";
import { contactServices } from "@service/contact.service";

const { mapMutations, mapState } = createNamespacedHelpers("salesOrderStore");

export default Vue.extend({
  name: "SalesOrderForm",
  components: {
    CSelectCustomer: () => import(/*webpackPrefetch: true*/"@/components/shared/select-customer/CSelectCustomer.vue"),
    CSelectShippingAddresss: () => import(/*webpackPrefetch: true*/"@/components/shared/select-shipping-address/CSelectShippingAddress.vue"),
    CSelectBillAddress: () => import(/*webpackPrefetch: true*/"@/components/shared/select-bill-address/CSelectBillAddress.vue"),
    CSelectFreight: () => import(/*webpackPrefetch: true*/"@/components/shared/select-freight/CSelectFreight.vue"),
    CSelectSalesType: () => import(/*webpackPrefetch: true*/"@/components/shared/select-sales-type/CSelectSalesType.vue"),
    CSelectCurrency: () => import(/*webpackPrefetch: true*/"@/components/shared/select-currency/CSelectCurrency.vue"),
    CSelectTermOfPayment: () => import(/*webpackPrefetch: true*/"@/components/shared/select-term-of-payment/CSelectTermOfPayment.vue"),
    CSelectTaxCalculation: () => import(/*webpackPrefetch: true*/"@/components/shared/select-tax-calculation/CSelectTaxCalculation.vue"),
  },
  mixins: [
    MNotificationVue,
  ],
  data() {
    return {
      DEFAULT_DATE_FORMAT,
      DEFAULT_TIME_FORMAT,
      form: {
        customerId: undefined as string | undefined,
        customer: "",
        customerContact: "",
        customerPoNumber: "",
        customerShippingAddress: undefined as string | undefined,
        etaDate: null as null | Moment,
        etaHour: null as null | Moment,
        freight: undefined as string | undefined,
        orderDate: null as null | Moment,
        branch: undefined as string | undefined,
        salesName: "",
        salesType: undefined as string | undefined,
        expiredDate: null as null | Moment,
        notes: "",
        customerBillAddress: undefined as string | undefined,
        currency: undefined as string | undefined,
        top: undefined as string | undefined,
        taxCalculation: TAX_CALCULATION.NONE,
        creditLimit: 0,
        jobCostingNumber: "",
        status: "Draft" as string | (string | null)[],
        jobCostingId: "",
      },
      rules: {
        customerId: [{ required: true, trigger: "change", message: () => this.$t("lbl_validation_required_error") }],
        customerContact: [{ required: true, trigger: "blur", message: () => this.$t("lbl_validation_required_error") }],
        customerPoNumber: [{ required: true, trigger: "blur", message: () => this.$t("lbl_validation_required_error") }],
        customerShippingAddress: [{ required: true, trigger: "change", message: () => this.$t("lbl_validation_required_error") }],
        etaDate: [{ required: true, trigger: "change", message: () => this.$t("lbl_validation_required_error") }],
        etaHour: [{ required: true, trigger: "change", message: () => this.$t("lbl_validation_required_error") }],
        freight: [{ required: true, trigger: "change", message: () => this.$t("lbl_validation_required_error") }],
        orderDate: [{ required: true, trigger: "change", message: () => this.$t("lbl_validation_required_error") }],
        branch: [{ required: true, trigger: "change", message: () => this.$t("lbl_validation_required_error") }],
        salesName: [{ required: true, trigger: "blur", message: () => this.$t("lbl_validation_required_error") }],
        salesType: [{ required: true, trigger: "change", message: () => this.$t("lbl_validation_required_error") }],
        expiredDate: [{ required: true, trigger: "change", message: () => this.$t("lbl_validation_required_error") }],
        customerBillAddress: [{ required: true, trigger: "change", message: () => this.$t("lbl_validation_required_error") }],
        currency: [{ required: true, trigger: "change", message: () => this.$t("lbl_validation_required_error") }],
        top: [{ required: true, trigger: "change", message: () => this.$t("lbl_validation_required_error") }],
        taxCalculation: [{ required: true, trigger: "change", message: () => this.$t("lbl_validation_required_error") }],
      },
      customerAddress: [] as IAddressDataList[],
    };
  },
  computed: {
    ...mapState({
      storeReqCreate: (state: any) => state.reqCreate,
      storeProductLines: (state: any) => state.productLines,
      storeDetailSO: (state: any) => state.detailSO,
      storeFormSO: (state: any) => state.formSO,
    }),
    formCol() {
      return {
        sm: { span: 24 },
        md: { span: 12 },
      };
    },
    showExpireDate(): boolean {
      return this.form.salesType?.toUpperCase() === "RESERVE";
    },
    isModeEdit(): boolean {
      return this.$route.meta.mode === Mode.EDIT;
    },
    isModeDetail(): boolean {
      return this.$route.meta.mode === Mode.DETAIL;
    },
    isModeCreate(): boolean {
      return this.$route.meta.mode === Mode.CREATE;
    },
  },
  watch: {
    "storeDetailSO.id": {
      immediate: true,
      handler: "fillForm"
    }
  },
  created() {
    if (this.$route.query.status) {
      this.form.status = this.$route.query.status;
    }
  },
  methods: {
    formatCurrency,
    reverseFormatNumber,
    formatterNumber,
    moment,
    initReqCreate,
    ...mapMutations({
      storeSetReqCreate: "SET_REQUEST_CREATE",
      storeSetDetailSO: "SET_DETAIL_SO",
      storeSetDeletedLine: "SET_DELETED_LINE",
      storeSetFormSO: "SET_FORM_SO",
    }),
    findCustomerById(id: string): Promise<ResponseContactData> {
      return contactServices.getContactData(id);
    },
    fillForm(): void {
      if (this.isModeCreate) return;
      const data: ResponseDetailSalesOrder = this.storeDetailSO;
      this.form = {
        customerId: data.customerId,
        customer: data.customerName,
        customerContact: data.customerContact,
        customerPoNumber: data.customerPoNumber,
        customerShippingAddress: data.customerShipToAddress,
        etaDate: data.etaDate ? this.moment(data.etaDate) : null,
        etaHour: data.etaDate ? this.moment(data.etaDate) : null,
        freight: data.freight,
        orderDate: data.orderDate ? this.moment(data.orderDate) : null,
        branch: data.branchId,
        salesName: data.salesName,
        salesType: data.salesType,
        expiredDate: data.expireDate ? this.moment(data.expireDate) : null,
        notes: data.notes,
        customerBillAddress: data.customerBillToAddress,
        currency: data.currencyId,
        top: data.termOfPayment + "", // convert to string
        taxCalculation: data.taxCalculation,
        creditLimit: data.creditLimit,
        jobCostingNumber: data.jobCostingNumber,
        status: data.status,
        jobCostingId: data.jobCostingId,
      };
      if (this.form.customerId) {
        this.initCustomerAddress(this.form.customerId);
      }
      this.storeSetReqCreate({
        ...this.storeReqCreate,
        ...{
          etaDate: this.form.etaDate?.format() || "",
          expireDate: this.form.expiredDate?.format() || null,
          orderDate: this.form.orderDate?.format() || ""
        }
      });
      this.commitStore();
    },
    commitStore(): void {
      const {
        customerId,
        customerContact,
        customerPoNumber,
        customerShippingAddress,
        freight,
        branch,
        salesName,
        salesType,
        notes,
        customerBillAddress,
        currency,
        top,
        taxCalculation,
        creditLimit,
        jobCostingId
      } = this.form;
      this.storeSetReqCreate({
        ...this.storeReqCreate,
        ...{
          customerId,
          customerContact,
          customerPoNumber,
          freight,
          customerShipToAddress: customerShippingAddress,
          branchId: branch,
          salesName,
          salesType,
          notes,
          customerBillToAddress: customerBillAddress,
          currencyId: currency,
          termOfPayment: top ? +top : null,
          taxCalculation,
          creditLimit,
          jobCostingId
        }
      });
      this.storeSetFormSO({
        ...this.storeFormSO,
        ...this.form
      });
    },
    async onSelectCustomer(e: {value: string, meta: IOption<ContactData>}): Promise<void> {
      this.customerAddress = e.meta.meta?.addressDataList || [];
      const shipTo = e.meta.meta?.addressDataList.find(x => x.shipTo && x.primaryShipTo) || undefined;
      const billTo = e.meta.meta?.addressDataList.find(x => x.billTo && x.primaryBillTo) || undefined;
      if (shipTo) {
        this.form.customerShippingAddress = `${shipTo.address}, ${shipTo.cityDistrict}, ${shipTo.country}, ${shipTo.postalCode}`;
      }
      if (billTo) {
        this.form.customerBillAddress = `${billTo.address}, ${billTo.cityDistrict}, ${billTo.country}, ${billTo.postalCode}`;
      }
      this.form.creditLimit = await this.getCustomerCreditLimit(e.value);
      this.commitStore();
    },
    async getCustomerCreditLimit(id: string): Promise<number> {
      try {
        const res = await this.findCustomerById(id);
        return res.customerData.creditLimit || 0;
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
        return 0;
      }
    },
    onChangeDate(): void {
      const etaDate = this.form.etaDate?.format() || "";
      const orderDate = this.form.orderDate?.format() || "";
      const expireDate = this.form.expiredDate?.format() || null;
      this.storeSetReqCreate({
        ...this.storeReqCreate,
        ...{ etaDate, orderDate, expireDate }
      });
    },
    onChangeHour(): void {
      let { etaDate, etaHour } = this.form;
      if (etaDate && etaHour) {
        this.etaDate = etaDate.set("hour", etaHour.hour());
        this.etaDate = etaDate.set("minute", etaHour.minute());
        this.storeSetReqCreate({
          ...this.storeReqCreate,
          ...{ etaDate: this.etaDate.format() }
        });
      }
      this.storeSetFormSO({
        ...this.storeFormSO,
        ...{ etaHour }
      });
    },
    async initCustomerAddress(custId: string): Promise<void> {
      try {
        const user = await this.findCustomerById(custId);
        this.customerAddress = user.addressDataList ?? [];
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      }
    },
    onSelectAddress(param: {value: string, meta: IOption}, source: "bill" | "ship"): void {
      const { value } = param;
      if (source === "ship") this.form.customerShippingAddress = value;
      else if (source === "bill") this.form.customerBillAddress = value;
      this.commitStore();
    },
  }
});
